// import React, { useEffect } from 'react';
// import { ChakraProvider, useMediaQuery } from '@chakra-ui/react';
// import GoogleMaps from './components/GoogleMaps';
// import Content from './components/Content';
// import theme from './theme';
// import Navbar from './components/Navbar';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import Phone from './components/Phone';
// import ReactGA from 'react-ga';

// function App() {
//   const [isMobile] = useMediaQuery('(max-width: 768px)');

//   useEffect(() => {
//     ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
//     ReactGA.pageview(window.location.pathname + window.location.search);
//   }, []);

//   return (
//     <ChakraProvider theme={theme}>
//       {isMobile ? (
//         <Phone />
//       ) : (
//         <>
//           <Navbar />
//           <Header />
//           <GoogleMaps />
//           <Content />
//           <Footer />
//         </>
//       )}
//     </ChakraProvider>
//   );
// }

// export default App;


import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, VStack, Flex, useColorModeValue } from '@chakra-ui/react';

function App() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  useEffect(() => {
    // Redirect to the target URL immediately
    window.location.href = 'https://scrapetable.com';
  }, []);

  return (
    <Flex minHeight="100vh" align="center" justify="center" bg={bgColor} color={textColor}>
      <VStack spacing={6}>
        <Heading>Scrapetable has moved!</Heading>
        <Text>Check out the new website at scrapetable.com</Text>
        <Button colorScheme="blue" onClick={() => window.location.href = 'https://scrapetable.com'}>
          Go to Scrapetable.com
        </Button>
      </VStack>
    </Flex>
  );
}

export default App;